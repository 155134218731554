import request from '@/utils/request';
export var getMissionInfoSettings = function getMissionInfoSettings(start, end, handArmFunction) {
  return request({
    url: "/connect/api/v1/mission/settings",
    method: 'get',
    params: {
      start: start,
      end: end,
      handArmFunction: handArmFunction
    }
  });
};
export var updateMissionInfoSettings = function updateMissionInfoSettings(data) {
  return request({
    url: "/connect/api/v1/mission/settings",
    method: 'post',
    data: data
  });
};