var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-container" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "15px" },
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.handArmFunctions, function(item) {
          return _c(
            "el-tab-pane",
            { key: item.key, attrs: { label: item.label, name: item.key } },
            [
              _c(
                "keep-alive",
                [
                  _vm.activeName === item.key
                    ? _c("severity-setting", {
                        attrs: { "hand-arm-function": item.key }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }